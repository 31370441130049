import toast from "react-hot-toast";
import { geocodeByAddress } from "react-places-autocomplete";
import {
  AllRoleTypes,
  FullPersonForSingleRoleForm,
  FirstDegreePeopleRole,
  PersonForBuilderForm,
  UserDataObj,
} from "src/types/snugtotal";
import dayjs from "dayjs";

export const sortRoles = (roles: FirstDegreePeopleRole[]) => {
  return roles.sort(
    (a, b) =>
      trustHiearchyOrder.indexOf(a.hierarchy || "PRIMARY") -
    trustHiearchyOrder.indexOf(b.hierarchy || "PRIMARY")
  );
};

export interface ContextValues {
  error: string;
  initialLoading: boolean;
  savingNewValues: boolean;
  deletingEntity?: boolean;
}

export const finalRestingPlaceMap = {
  CREMATION: "Cremation",
  BURIAL: "Burial",
  OTHER: "Other",
};

export const organDonationMap = {
  YES: "Yes, I want to donate my organs/tissues",
  NO: "No, I don't want to donate my organs/tissues",
  OTHER: "Other",
};

export const peopleRoleRelationshipMap = {
  CHILD: "Child",
  STEP_CHILD: "Step Child",
  OTHER_DEPENDENT: "Other Dependent",
  RELATIVE: "Relative",
  SPOUSE: "Spouse",
  PARENT: "Parent",
  GRANDPARENT: "Grandparent",
  OTHER: "Other",
  CHARITY: "Charity",
  SIBLING: "Sibling",
  FRIEND: "Friend",
  PET: "Pet",
  GRANDCHILD: "Grandchild",
  PRIMARY_CARE_PHYSICIAN: "Primary Care Physician",
  MEDICAL_SPECIALIST: "Medical Specialist",
  DENTIST: "Dentist",
  THERAPIST: "Therapist",
  MEDICAL_OTHER: "Medical Other",
  FINANCIAL_ADVISOR: "Professional",
  ATTORNEY: "Attorney",
  ACCOUNTANT: "Accountant",
  INSURANCE_BROKER: "Insurance Broker",
  REAL_ESTATE_AGENT: "Real Estate Agent",
  PROFESSIONAL_OTHER: "Professional Other",
  FINANCIAL_POWER_OF_ATTORNEY_AGENT: "Financial Power of Attorney Agent",
  CHILD_GUARDIAN: "Child Guardian",
  HEALTHCARE_DIRECTIVE_AGENT: "Healthcare Directive Agent",
  PET_GUARDIAN: "Pet Guardian",
  TRUSTEE: "Trustee",
  EXECUTOR: "Executor",
  GIFT_RECIPIENT: "Gift Recipient",
  CUSTOM_PLAN_BENEFICIARY: "Beneficiary",
  SNUG_OWNER: "Owner",
  AUTH_READONLY: "Read Only",
  AUTH_COLLABORATOR: "Collaborator",
  FINANCIAL_PROFESSIONAL_CLIENT: "Client",
};

export const professionalRoleTypesArray = [
  "PRIMARY_CARE_PHYSICIAN",
  "MEDICAL_SPECIALIST",
  "DENTIST",
  "THERAPIST",
  "MEDICAL_OTHER",
  "FINANCIAL_ADVISOR",
  "ATTORNEY",
  "ACCOUNTANT",
  "INSURANCE_BROKER",
  "REAL_ESTATE_AGENT",
  "PROFESSIONAL_OTHER",
];

export const financialProfessionalRoleTypesArray = [
  "FINANCIAL_ADVISOR",
  "ATTORNEY",
  "ACCOUNTANT",
  "INSURANCE_BROKER",
  "REAL_ESTATE_AGENT",
  "PROFESSIONAL_OTHER",
];

export const medicalProfessionalRoleTypesArray = [
  "PRIMARY_CARE_PHYSICIAN",
  "MEDICAL_SPECIALIST",
  "DENTIST",
  "THERAPIST",
  "MEDICAL_OTHER",
];

export const humanRelationshipArray = [
  "SPOUSE",
  "CHILD",
  "STEP_CHILD",
  "OTHER_DEPENDENT",
  "RELATIVE",
  "PARENT",
  "GRANDPARENT",
  "OTHER",
  "SIBLING",
  "FRIEND",
  "GRANDCHILD",
  "CHARITY",
];

export const estatePlanningRolesArray = [
  "FINANCIAL_POWER_OF_ATTORNEY_AGENT",
  "CHILD_GUARDIAN",
  "HEALTHCARE_DIRECTIVE_AGENT",
  "PET_GUARDIAN",
  "TRUSTEE",
  "EXECUTOR",
  "CUSTOM_PLAN_BENEFICIARY",
  "GIFT_RECIPIENT",
];

// create concatenated array of roles starting with humanRelationshipArray and then professionalRoleTypesArray and then estatePlanningRolesArray
export const allRoleTypesArray = [
  ...humanRelationshipArray,
  ...professionalRoleTypesArray,
  ...estatePlanningRolesArray,
];

export const authorizationRolesArray = ["AUTH_READONLY", "AUTH_COLLABORATOR"];

export const rolesWithHierarchy = [
  "FINANCIAL_POWER_OF_ATTORNEY_AGENT",
  "CHILD_GUARDIAN",
  "HEALTHCARE_DIRECTIVE_AGENT",
  "PET_GUARDIAN",
  "TRUSTEE",
  "EXECUTOR",
];

export const giftTypeMap = {
  CASH: "Cash",
  REAL_ESTATE: "Real Estate",
  // PERSONAL_ITEM: "Personal Item", may uncomment one day
  // PET_STIPEND: "Pet Stipend",
  // CHILD_STIPEND: "Child Stipend",
  VEHICLE: "Vehicles",
  FURNITURE: "Furniture",
  JEWELRY: "Jewelry",
  CLOTHING: "Clothing",
  ARTWORK: "Artwork",
  COLLECTIBLES: "Collectibles",
  OTHER_PERSONAL_PROPERTY: "Other Personal Property",
};

export const estatePlanningRolesOptions = [
  {
    label: "Financial Power of Attorney Agent",
    value: "FINANCIAL_POWER_OF_ATTORNEY_AGENT",
  },
  { label: "Child Guardian", value: "CHILD_GUARDIAN" },
  { label: "Healthcare Directive Agent", value: "HEALTHCARE_DIRECTIVE_AGENT" },
  { label: "Pet Guardian", value: "PET_GUARDIAN" },
  { label: "Trustee", value: "TRUSTEE" },
  { label: "Executor", value: "EXECUTOR" },
  { label: "Beneficiary", value: "CUSTOM_PLAN_BENEFICIARY" },
  { label: "Gift Recipient", value: "GIFT_RECIPIENT" },
];

export const authRoleOptions = [
  { label: "Read Only", value: "AUTH_READONLY" },
  { label: "Collaborator", value: "AUTH_COLLABORATOR" },
];

export const giftOptions = [
  { label: "Cash", value: "CASH" },
  { label: "Real Estate", value: "REAL_ESTATE" },
  // { label: "Personal Item", value: "PERSONAL_ITEM" }, may bring back one day
  // { label: "Pet Stipend", value: "PET_STIPEND" },
  // { label: "Child Stipend", value: "CHILD_STIPEND" },
  { label: "Vehicle", value: "VEHICLE" },
  { label: "Furniture", value: "FURNITURE" },
  { label: "Jewelry", value: "JEWELRY" },
  { label: "Clothing", value: "CLOTHING" },
  { label: "Artwork", value: "ARTWORK" },
  { label: "Collectibles", value: "COLLECTIBLES" },
  { label: "Other Personal Property", value: "OTHER_PERSONAL_PROPERTY" },
];

export const relationshipOptions = [
  { label: "Spouse / Partner", value: "SPOUSE" },
  { label: "Child", value: "CHILD" },
  { label: "Parent", value: "PARENT" },
  { label: "Sibling", value: "SIBLING" },
  { label: "Grandchild", value: "GRANDCHILD" },
  { label: "Grandparent", value: "GRANDPARENT" },
  { label: "Friend", value: "FRIEND" },
  { label: "Pet", value: "PET" },
  { label: "Relative", value: "RELATIVE" },
  { label: "Step Child", value: "STEP_CHILD" },
  { label: "Charity", value: "CHARITY" },
  { label: "Other Dependent", value: "OTHER_DEPENDENT" },
  { label: "Other", value: "OTHER" },
];

export const medicalRoleOptions = [
  { label: "Primary Care Physician", value: "PRIMARY_CARE_PHYSICIAN" },
  { label: "Medical Specialist", value: "MEDICAL_SPECIALIST" },
  { label: "Dentist", value: "DENTIST" },
  { label: "Therapist", value: "THERAPIST" },
  { label: "Other", value: "MEDICAL_OTHER" },
];

export const financialRoleOptions = [
  { label: "Financial Advisor", value: "FINANCIAL_ADVISOR" },
  { label: "Attorney", value: "ATTORNEY" },
  { label: "Accountant", value: "ACCOUNTANT" },
  { label: "Insurance Broker", value: "INSURANCE_BROKER" },
  { label: "Real Estate Agent", value: "REAL_ESTATE_AGENT" },
  { label: "Professional Other", value: "PROFESSIONAL_OTHER" },
];

export const maritalStatusOptions = [
  {
    label: "",
    value: undefined,
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Domestic Partnership",
    value: "domestic_partnership",
  },
  {
    label: "Civil Union",
    value: "civil_union",
  },
];

export const utilityTypeMap = {
  ELECTRIC: "Electric",
  GAS_OIL: "Gas / Oil",
  INTERNET_TELECOM: "Internet / Telecom",
  CABLE_SATELLITE: "Cable / Satellite",
  TRASH_RECYLING: "Trash / Recycling",
  OTHER: "Other",
};

export const maritalStatusMap = {
  married: "Married",
  domestic_partnership: "Domestic Partnership",
  civil_union: "Civil Union",
};

export const dependentsRelationshipArray = [
  "child",
  "step_child",
  "other_dependent",
];

export const parseStateAndPostalCode = async (address: string | undefined) => {
  let state_code = "";
  let tmpPostalCode = "";
  if (address) {
    await geocodeByAddress(address).then((results) => {
      state_code =
        results[0]?.address_components?.find((item) =>
          item.types.find((item) => item === "administrative_area_level_1")
        )?.short_name || "";
      tmpPostalCode =
        results[0]?.address_components?.find((item) =>
          item.types.find((item) => item === "postal_code")
        )?.short_name || "";
    });
  }
  return { state_code, tmpPostalCode };
};

export const timeAgo = (timestamp: Date | null) => {
  if (!timestamp) return "";
  const createdAt =
    new Date(timestamp).getTime() + new Date().getTimezoneOffset() * 60000;
  const now = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
  const diff = now - createdAt;
  let diffDays = Math.floor(diff / (1000 * 3600 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffDays < 0) {
    diffDays = 0;
  }

  if (diffYears > 0) {
    return `${diffYears} year${diffYears === 1 ? "" : "s"} ago`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths === 1 ? "" : "s"} ago`;
  } else {
    return `${diffDays} day${diffDays === 1 ? "" : "s"} ago`;
  }
};

export type Hierarchy = "PRIMARY" | "SECONDARY" | "TERTIARY" | "QUATERNARY" | "QUINARY" | "SENARY" | "SEPTENARY" | "OCTONARY" | "NONARY" | "DENARY";
export const standardHierarchyOrder: Hierarchy[] = ["PRIMARY", "SECONDARY", "TERTIARY"];
export const trustHiearchyOrder: Hierarchy[] = ["PRIMARY", "SECONDARY", "TERTIARY", "QUATERNARY", "QUINARY", "SENARY", "SEPTENARY", "OCTONARY", "NONARY", "DENARY"];

export const getNextHierarchy = (
  filteredPeopleRoles: FirstDegreePeopleRole[], hierarchyOrder: Hierarchy[]
): Hierarchy | null => {
  const existingHierarchies = filteredPeopleRoles.map((role) => role.hierarchy);

  // Find the lowest missing hierarchy
  const nextHierarchy = hierarchyOrder.find(
    (hierarchy) => !existingHierarchies.includes(hierarchy)
  );

  return nextHierarchy || null;
};

export const setHiddenSeedsInStorage = (
  value: string,
  data_key: string,
  user_id: number
) => {
  const hiddenSeeds = localStorage
    .getItem(`hs_${data_key}_${user_id}`)
    ?.split(",");
  if (hiddenSeeds) {
    if (!hiddenSeeds.includes(value)) {
      localStorage.setItem(
        `hs_${data_key}_${user_id}`,
        `${hiddenSeeds},${value}`
      );
    }
  } else {
    localStorage.setItem(`hs_${data_key}_${user_id}`, value);
  }
  return localStorage.getItem(`hs_${data_key}_${user_id}`)?.split(",");
};

export const hierarchyMap = {
  PRIMARY: "Primary",
  SECONDARY: "1st Backup",
  TERTIARY: "2nd Backup",
  QUATERNARY: "3rd Backup",
  QUINARY: "4th Backup",
  SENARY: "5th Backup",
  SEPTENARY: "6th Backup",
  OCTONARY: "7th Backup",
  NONARY: "8th Backup",
  DENARY: "9th Backup"
};

export const downloadFileFromS3 = async (
  presignedUrl: string,
  fileName: string
) => {
  try {
    // Fetch the file content from the presigned URL
    const fileResponse = await fetch(presignedUrl);
    const blob = await fileResponse.blob();

    // Create an anchor element and set the attributes
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    toast.error(
      "There was an error downloading the file. Please try again later."
    );
  }
};

export const isMinor = (
  peopleRole: FirstDegreePeopleRole,
  target_user_datas: UserDataObj[]
) => {
  const person = target_user_datas.find(
    (user) => user.ud_id === peopleRole.role_target_user_data_ud_id
  );
  if (!person?.birthday) return false;
  const birthday = dayjs(person.birthday);
  const today = dayjs();
  const age = today.diff(birthday, "year");
  return age < 18;
};

export const buildFullPersonForBuilderForm = (
  // inEditRole: FirstDegreePeopleRole,
  allPeopleRoles: FirstDegreePeopleRole[],
  targetUserData: UserDataObj | null
) => {
  const partialPerson: PersonForBuilderForm = {
    role_target_user_data: {} as UserDataObj,
    relationship_role: {} as FirstDegreePeopleRole,
  };
  if (!targetUserData) return partialPerson;
  // from the inEditRole, set the target_user_data from the role_target_user_data_ud_id
  partialPerson.role_target_user_data = targetUserData || ({} as UserDataObj);

  // from all people Role find the relationship role from the list of personal relationships humanRelationshipArray, use the target_user_data_ud_id to find the role
  const relationshipRole = allPeopleRoles
    .filter((r) => !estatePlanningRolesArray.includes(r.role || ""))
    .find((role) => role.role_target_user_data_ud_id === targetUserData?.ud_id);

  partialPerson.relationship_role =
    relationshipRole || ({} as FirstDegreePeopleRole);

  return partialPerson;
};

export const buildFullPersonForSingleRoleForm = (
  inEditRole: FirstDegreePeopleRole,
  targetUserData: UserDataObj | null
) => {
  const fullPeople: FullPersonForSingleRoleForm = {
    role_target_user_data: {} as UserDataObj,
    relationship_role: {} as FirstDegreePeopleRole,
    birthday: null,
    submit: null,
  };
  // from the inEditRole, set the target_user_data from the role_target_user_data_ud_id
  fullPeople.role_target_user_data = targetUserData || ({} as UserDataObj);

  // set document Role From inEditRole
  fullPeople.relationship_role = {
    ...inEditRole,
  };
  fullPeople.birthday = targetUserData?.birthday || null;

  return fullPeople;
};

// we want to create a function that filters the roles for the drawer based on the rolesToFilterOn, roleInEdit, and filterOutMinority.
// for each of these filters, if we find a role that matches the filter, we want to filter out that role AND the roles that have the same role_target_user_data_ud_id as the filtered role.
// roleInEdit we find by matching the peopleRoles Id, rolesToFilterOn we find by matching the role, and filterOutMinority we find by matching the isMinor function.
//also for the roleInEdit, we want to get the role value and ensure that no other roles with the same estate_document_id are included in the filtered list (but in this case we dont' have to remove all other roles with the same role_target_user_data_ud_id)
export const filterRolesForDrawer = (
  peopleRoles: FirstDegreePeopleRole[],
  roleInEdit: FirstDegreePeopleRole | null,
  currentRoleToFilter: AllRoleTypes,
  rolesToFilterOn: string[],
  filterOutMinority: boolean,
  estate_document_id: string | null,
  allTargetUserData: UserDataObj[]
) => {
  if (!estate_document_id) {
    return [];
  }

  const exclusionArray = peopleRoles.filter(
    (role) =>
      rolesToFilterOn.includes(role.role || "") ||
      role.id === roleInEdit?.id ||
      (role.estate_document_id === estate_document_id &&
        role.role === currentRoleToFilter) ||
      (filterOutMinority && isMinor(role, allTargetUserData))
  );

  return peopleRoles.filter(
    (role) =>
      !exclusionArray.find(
        (exclusionRole) =>
          exclusionRole.role_target_user_data_ud_id ===
          role.role_target_user_data_ud_id
      ) && !authorizationRolesArray.includes(role.role || "")
  );
};

export const filterRolesForSharing = (
  peopleRoles: FirstDegreePeopleRole[],
  existingRolesToExclude: FirstDegreePeopleRole[],
  rolesToFilterOn: string[],
  filterOutMinority: boolean,
  allTargetUserData: UserDataObj[]
) => {
  const exclusionArray = peopleRoles.filter(
    (role) =>
      rolesToFilterOn.includes(role.role || "") ||
      existingRolesToExclude.find(
        (existingRole) =>
          existingRole.role_target_user_data_ud_id ===
          role.role_target_user_data_ud_id
      ) ||
      (filterOutMinority && isMinor(role, allTargetUserData))
  );

  return peopleRoles.filter(
    (role) =>
      !exclusionArray.find(
        (exclusionRole) =>
          exclusionRole.role_target_user_data_ud_id ===
          role.role_target_user_data_ud_id
      )
  );
};

export const filterRolesForPermissionPopup = (
  peopleRoles: FirstDegreePeopleRole[],
  roleInEdit: FirstDegreePeopleRole | null,
  rolesToFilterOn: string[],
  allTargetUserData: UserDataObj[]
) => {
  const exclusionArray = peopleRoles.filter(
    (role) =>
      rolesToFilterOn.includes(role.role || "") ||
      role.id === roleInEdit?.id ||
      isMinor(role, allTargetUserData)
  );

  return peopleRoles.filter(
    (role) =>
      !exclusionArray.find(
        (exclusionRole) =>
          exclusionRole.role_target_user_data_ud_id ===
          role.role_target_user_data_ud_id
      )
  );
};

// src/utils/setFavicons.ts
export const setFavicons = (): void => {
  const currentDomain = window.location.hostname;

  const isDefaultDomain =
    currentDomain === process.env.REACT_APP_DEFAULT_SNUG_DOMAIN;
  const faviconPath = isDefaultDomain ? "snug-" : "wl-";

  const favicons = [
    { rel: "icon", sizes: "16x16", href: `${faviconPath}favicon-16x16.png` },
    { rel: "icon", sizes: "32x32", href: `${faviconPath}favicon-32x32.png` },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: `${faviconPath}apple-touch-icon.png`,
    },
    { rel: "shortcut icon", href: `${faviconPath}favicon.ico` },
  ];

  // Remove existing favicons
  const links = document.querySelectorAll<HTMLLinkElement>(
    'link[rel*="icon"], link[rel="apple-touch-icon"]'
  );
  links.forEach((link) => link.parentNode?.removeChild(link));

  // Add new favicons
  favicons.forEach((favicon) => {
    const link = document.createElement("link");
    link.rel = favicon.rel;
    if (favicon.sizes) {
      link.setAttribute("sizes", favicon.sizes);
    }
    link.href = favicon.href;
    document.head.appendChild(link);
  });
};
