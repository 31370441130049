import { useCallback, useReducer } from "react";
import toast from "react-hot-toast";
import {
  GenericPath,
  useV3Create,
  useV3DocumentDownload,
  useV3GenericGetData,
} from "src/api/snugtotal/estate_app";

import { useMounted } from "src/hooks/use-mounted";
import { MinimalEstateDocument } from "src/types/snugdocs";
import { downloadFileFromS3 } from "src/utils/snug";
import { slackHook } from "src/utils/snug-slack-hooks";

type EstatePlanSummaryStoreAction =
  | {
      type: "SET_SINGLE_ESTATE_PLAN_SUMMARY";
      payload: MinimalEstateDocument;
    }
  | {
      type: "SET_POLLING_STATUS";
      payload: "PENDING" | "SUCCESS" | "FAILURE" | null;
    }
  | {
      type: "SET_ESTATE_PLAN_SUMMARY_LOADING";
      payload: boolean;
    }
  | {
      type: "SET_SUMMARY_DOWNLOADING";
      payload: boolean;
    };

// Define reducer function for useEstatePlanSummaryStore
const EstatePlanSummaryStoreReducer = (
  state: EstatePlanSummaryStoreState,
  action: EstatePlanSummaryStoreAction
): EstatePlanSummaryStoreState => {
  switch (action.type) {
    case "SET_SINGLE_ESTATE_PLAN_SUMMARY":
      return { ...state, singleEstatePlanSummary: action.payload };
    case "SET_POLLING_STATUS":
      return { ...state, pollingStatus: action.payload };
    case "SET_ESTATE_PLAN_SUMMARY_LOADING":
      return { ...state, estatePlanSummaryLoading: action.payload };
    case "SET_SUMMARY_DOWNLOADING":
      return { ...state, summaryDownloading: action.payload };
    default:
      return state;
  }
};

interface EstatePlanSummaryStoreState {
  singleEstatePlanSummary: MinimalEstateDocument | null;
  pollingStatus: "PENDING" | "SUCCESS" | "FAILURE" | null;
  estatePlanSummaryLoading: boolean;
  summaryDownloading: boolean;
}

export const useEstatePlanSummaryStore = (ud_id: string | null) => {
  const isMounted = useMounted();
  
  const [state, dispatch] = useReducer(EstatePlanSummaryStoreReducer, {
    singleEstatePlanSummary: null,
    pollingStatus: null,
    estatePlanSummaryLoading: true,
    summaryDownloading: false,
  });

  const genericGetData = useV3GenericGetData<MinimalEstateDocument>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const postSummary = useV3Create<MinimalEstateDocument>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );

  const genericGetDataForDownload = useV3DocumentDownload(
    GenericPath.GENERIC_DOCUMENT_DOWNLOAD_V3
  );

  //   function return SummaryCretateResponse or null if not present
  const handlePostNewEstatePlanSummary = useCallback(async () => {
    try {
      if (!ud_id) {
        throw new Error("No ud_id found");
      }
      const response = await postSummary(
        {
          ud_id: ud_id,
          entity: "estate-summary",
        },
        {} as any
      );
      return response;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      if (isMounted()) {
        dispatch({
          type: "SET_ESTATE_PLAN_SUMMARY_LOADING",
          payload: false,
        });
      }
    }
  }, [isMounted, postSummary, ud_id]);

  const handleDownloadEstateDocumentDocument = useCallback(
    async (estateDocument_id: string | null, document_id: string | null) => {
      toast.success("Downloading file...");

      if (!document_id || !estateDocument_id || !ud_id) {
        toast.error("Failed to download file");
        return;
      }

      const params = {
        ud_id: ud_id,
        entity: "estate-summary",
        entity_id: estateDocument_id,
        document_id: document_id,
      };
      dispatch({
        type: "SET_SUMMARY_DOWNLOADING",
        payload: true,
      });
      genericGetDataForDownload(params)
        .then((res: any) => {
          if (res) {
            // download File from s3 res and get file name from mathcing document id in EstateDocument objects' documents array
            downloadFileFromS3(res, "Estate-Plan-Summary.pdf");
          } else {
            slackHook(
              `Document Error: Failed to get document with message "No response"`
            );
          }
        })
        .catch((err) => {
          toast.error("Failed to download file");
          slackHook(
            `Document Error: Failed to get document with message "${err}"`
          );
        })
        .finally(() => {
          dispatch({
            type: "SET_SUMMARY_DOWNLOADING",
            payload: false,
          });
        });
    },
    [genericGetDataForDownload, ud_id]
  );

  const handleCreateNewSummaryAndPoll = useCallback(async () => {
    // set is Polling
    dispatch({
      type: "SET_POLLING_STATUS",
      payload: "PENDING",
    });

    // post new summary and use response id to poll
    const response = await handlePostNewEstatePlanSummary();

    if (!response?.id || !ud_id) {
      dispatch({
        type: "SET_POLLING_STATUS",
        payload: "FAILURE",
      });
      return;
    }

    const pollingId = response?.id;

    let loopCount = 0;
    const pollForNewSummary = async () => {
      try {
        const response = await genericGetData({
          ud_id: ud_id,
          entity: "estate-summary",
          entity_id: pollingId,
        });
        if (response?.status === "APPROVED") {
          dispatch({
            type: "SET_POLLING_STATUS",
            payload: "SUCCESS",
          });

          // set singleEstatePlanSummary to response
          dispatch({
            type: "SET_SINGLE_ESTATE_PLAN_SUMMARY",
            payload: response,
          });
          handleDownloadEstateDocumentDocument(
            response?.id,
            response?.documents?.[0]?.document_id || null
          );
          return;
        } else if (response?.status === "ERROR") {
          dispatch({
            type: "SET_POLLING_STATUS",
            payload: "FAILURE",
          });
          return;
        } else if (loopCount > 6) {
          dispatch({
            type: "SET_POLLING_STATUS",
            payload: "FAILURE",
          });
          return;
        } else {
          loopCount++;
          setTimeout(pollForNewSummary, 2000);
        }
      } catch (err) {
        dispatch({
          type: "SET_POLLING_STATUS",
          payload: "FAILURE",
        });
      }
    };

    setTimeout(pollForNewSummary, 2000);
  }, [
    genericGetData,
    handlePostNewEstatePlanSummary,
    handleDownloadEstateDocumentDocument,
    ud_id,
  ]);

  return {
    ...state,
    handleCreateNewSummaryAndPoll,
  };
};
